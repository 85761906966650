
@import './custom.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';


@import './ssb.scss';




/* reset */
/* 
html,
body,
header,
nav,
h1,
a,
ul,
li,
strong,
main,
i,
section,
img,
div,
h2,
p,
form,
fieldset,
label,
input,
textarea,
span,
article,
footer,
time,
small {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
    box-sizing: inherit;
    background: transparent;
    font-family: 'Retina', Helvetica, Arial, sans-serif;
}

ul {
    list-style: none;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

input[type="password"],
input[type="email"],
input[type="text"],
input[type="submit"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
}

button,
input[type="submit"] {
    cursor: pointer;
}

button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
    box-sizing: inherit;
    background: transparent;
    font-family: Open Sans, Open Sans Hebrew, Arial;;
} */