body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #f1f3f6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 700;
}
.border-w-2{
    border-width: 2px;
}
.navbar-dark {
    .navbar-nav {
        .nav-link {
            color: rgba(#ffffff, 0.8);
        }
        // .nav-link.active {
        //     font-weight: 600;
        // }

        .nav-link {
            color: rgba(#ffffff, 1);
        }

        .nav-link:hover {
            color: rgba(#ffffff, 0.8);
        }
    }
}

.dropdown-menu[aria-labelledby=basic-nav-dropdown]{
    right: 0;
    left: initial;
}
