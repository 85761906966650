.speaker-filter-section{
    background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    rgba(0,0,0,0.55) url(../../assets/images/events-header.jpg) no-repeat center;
    background-size: cover;
    color: #fff;
    margin: 0;
  
}

.rw-multiselect .rw-input-reset{
    height: calc(2.429em - 18px)!important;
    vertical-align: sub;
    margin-bottom: 8px;
}


.profile-item-container {
    background: #ffffff;
    padding: 40px 30px;  
    overflow: hidden;
    align-items: center;
    position: relative;

    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    height: 100%;
}
.profile-info_svg {
  position: absolute;
  left: 0;
  top: 0;
}
.profile-info_svg img{
    width: 70%;
}
.profile-img-wrap {

  float: left;
  margin-right: 30px;
}
.profile-img img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: contain;
}


.profile-social-icons-wrap{

    background-color: #ec008c;
    padding: 0.5em;
    display: flex;
    border-radius: 2em;
    justify-content: space-between;
    
    max-width: 190px;
    margin: 1em auto;
  
}

.profile-social-icon{

    width: 1.5em;
    height: 1.5rem;
    color: #ffffff;   
    display: flex;  
    align-items: center;
    justify-content: center;
}

.profile-info h4{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
}
.profile-info p{
   
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.8;
}
.profile-info small{
   
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.8;
}
.uploadcare--widget{
    display: flex;
    align-items: center;
    padding: 0.3em;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.uploadcare--widget__button{ 
    margin: 2px;
}

.uploadcare--widget__button.uploadcare--widget__button_type_open{
    display: inline-block;
    background-color: #ec008c;
    margin-left: auto;
}


@media screen and (max-width: 1023px) {
    .profile-info_svg, .profile-info_svg img{
        width: 100%;
    }

    .profile-img-wrap {

        float: none;
        margin-right: auto;
        margin-left: auto;
        width: 60%;
      }
      .profile-img{
          text-align: center;
      }
   
  }