.side-stick {
  position: absolute;
  width: 3px;
  height: 35px;
  left: 0;
  background-color: rgba(82, 95, 127, 0.5);
}

.event-tile-desc{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
    font-size: 95%;

}

.font-size-12{
  font-size: 12px;
}

.badge-light {
  color: #212529;
  background-color: #eaeaea;
}